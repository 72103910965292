<template>
  <div class="password">
    <div class="public-screen user-flipcar-screen middle-box animated fadeIn">
      <b-row class="justify-content-end">
        <img class="app-logo-sm" src="@/assets/images/flipcar_logo.png" alt="Logo"/>
      </b-row>
      <h2 class="m-t-xl m-b-md">
        {{ !success ? $t("user.flipcar.password.header.enter_new_password") : $t("user.flipcar.password.header.successfully_changed") }}</h2>

      <b-form v-if="!success" @submit.prevent="onSubmit">
        <generic-input
            label="common.new_password"
            v-model="password"
            type="password"
            required
        ></generic-input>

        <generic-input
            label="common.password_confirmation"
            v-model="password_confirmation"
            type="password"
            required
        ></generic-input>

        <div class="text-center">
          <b-button class="m-b" type="submit" variant="primary">
            {{ $t('button.change_password') }}
          </b-button>
        </div>
      </b-form>

      <div v-else class="m-t-lg">
        <p>{{ $t("user.flipcar.password.content.successfully_changed") }}</p>
        <p>{{ $t("user.flipcar.password.footer.your_team") }}</p>
      </div>

      <div class="text-center">
        <hr>
        <template v-for="(link, index) in linkList">
          <span v-if="index > 0"> | </span>
          <router-link :to="link.to" target="_blank">{{ $t(link.name) }}</router-link>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import {updatePasswordByResetToken} from "@/services/users";
import GenericInput from "@/components/generic_input.vue";

export default {
  components: {GenericInput},
  data() {
    return {
      success: false,
      password: '',
      password_confirmation: '',
      linkList: [
        {
          to: {name: "user_flipcar_privacy"},
          name: "common.privacy_policy",
        },
        {
          to: {name: "user_flipcar_terms"},
          name: "common.terms_of_use"
        },
        {
          to: {name: "user_flipcar_imprint"},
          name: "common.imprint"
        }
      ]
    }
  },
  methods: {
    onSubmit(event) {
      updatePasswordByResetToken(this.$route.query.reset_password_token, this.password, this.password_confirmation).then(response => {
        this.success = true
      })
    },
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">

</style>