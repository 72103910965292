import { render, staticRenderFns } from "./password.vue?vue&type=template&id=5473e48d&scoped=true&"
import script from "./password.vue?vue&type=script&lang=js&"
export * from "./password.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5473e48d",
  null
  
)

export default component.exports